import React, { useEffect } from "react"
import "../styles/main.css"

const Ad = ({ currentPath, adClass }) => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [currentPath])

  return (
    <div>
      <ins
        className={adClass}
        data-ad-client="ca-pub-3929077265792059"
        data-ad-slot="3307983214"
        // data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  )
}

export default Ad
