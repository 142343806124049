import React from "react"
import Image from "gatsby-image"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/main.css"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/seo"
import Ad from "../components/adsense"

const Template = ({ data, location }) => {
  //console.log(props) to see context in action
  const post = data.mdx
  const { mdx } = data
  const alt = mdx.frontmatter.alt
  const title = mdx.frontmatter.title
  const date = mdx.frontmatter.date
  const author = mdx.frontmatter.author
  const description = mdx.frontmatter.description
  const keywords = mdx.frontmatter.keywords

  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={description}
        keywords={keywords}
      />

      <div className="blog__container">
        <div className="blog__wrapper">
          <div className="ad__banner">
            <Ad currentPath={location} adClass="adsbygoogle ad_top" />
          </div>
          <div className="content__container">
            <Image
              fluid={mdx.frontmatter.image.childImageSharp.fluid}
              className="img__post"
              alt={alt}
            />
            <div className="content__top">
              <h1 className="blog__title">{title}</h1>
              <div className="blog__wrap">
                <h2 className="blog__author">
                  Written by <strong>{author}</strong>
                </h2>
                <time dateTime="true" className="blog__date">
                  Published on {date}
                </time>
              </div>
            </div>
            <MDXRenderer>{post.body}</MDXRenderer>
            <Ad currentPath={location} adClass="adsbygoogle ad_bottom" />
            {/* <div>{next && <Link to={next.frontmatter.path}>Next</Link>}</div>
            <div>{prev && <Link to={prev.frontmatter.path}>Prev</Link>}</div> */}
          </div>
          <div className="sidebar__column">
            <div className="sidebar__content">
              <Ad currentPath={location} adClass="adsbygoogle ad_side" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        keywords
        alt
        date(formatString: "MMMM DD, YYYY")
        title
        author
        description
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Template
